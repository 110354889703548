import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 14 14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#E7E6E6",
      d: "m7.003.66 2.06 4.173 4.606.674-3.333 3.246.787 4.587-4.12-2.166-4.12 2.166.786-4.587L.336 5.507l4.607-.674z"
    }, null, -1)
  ])))
}
export default { render: render }